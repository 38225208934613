import axios from 'axios'
import {
  AccountInfoDto,
  MultiSericeDto,
  PaymentMethod,
  PaymentTerm,
  RecurringBooking2ServiceDto,
  ServiceCategoryDto,
  ServiceDto,
  TaskStatus
} from '../definition'
import { CONFIG_PREFFER_PAYMENTTERM } from '../constants/booking.config'
import { TimeSlotSession } from '../definition/time-slot.dto'
import { concat } from 'lodash'
import { SelectedServiceCategoryDto } from '../views/pages/management/service/hierarchy-categories'

export * from './event-emitter'
export * from './url-util'
export * from './enum-util'
export * from './quote-caculation'

export const nonceFunction: any = () => {}

const tempPreventMouseEvent = (callback: Function, event: React.MouseEvent) => {
  event.preventDefault()
  callback()
}
export const preventMouseEvent = (callback: Function) => {
  return tempPreventMouseEvent.bind(null, callback)
}

export const fetchImage = async (imageUrl: string) => {
  const response = await axios.get(imageUrl, { responseType: 'blob' })
  return URL.createObjectURL(response.data)
}

export const getImageSize = (file: File): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    let objectUrl = URL.createObjectURL(file)
    img.onload = function () {
      const self = this as any
      resolve({ width: self.width, height: self.height })
    }
    img.onerror = function (error) {
      reject(error)
    }
    img.src = objectUrl
  })
}

export const putToS3 = async (fileObject: File, presignedUrl: string) => {
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': fileObject.type
    },
    body: fileObject
  }
  return await fetch(presignedUrl, requestOptions)
}

class EnumUtil {
  static getNamesAndValues<T extends string | number>(enumType: any): { name: string; value: T }[] {
    return this.getNames(enumType).map((_name) => {
      return { name: _name, value: enumType[_name] as T }
    })
  }

  static getNames(enumType: any): string[] {
    return Object.keys(enumType).filter((key) => isNaN(+key))
  }

  static getNameFromValue<T extends string | number>(enumType: any, value: T): string | null {
    const all = this.getNamesAndValues(enumType).filter((pair) => pair.value === value)
    return all.length === 1 ? all[0].name : null
  }

  static getValues<T extends string | number>(enumType: any): T[] {
    return this.getNames(enumType).map((name) => enumType[name]) as T[]
  }
}
export const getEnumValues = (enumType: any) => EnumUtil.getValues(enumType)

export const getPaymentTermLlabel = (paymentTerm: PaymentTerm | undefined) => {
  if (!paymentTerm) {
    return ''
  }
  let label
  switch (paymentTerm) {
    case PaymentTerm.COD:
      label = 'Cash On Delivery'
      break
    case PaymentTerm.CREDIT:
      label = 'Credit Terms'
      break
    case PaymentTerm.PPP:
      label = 'Partial Pre-Payment '
      break
    case PaymentTerm.FPP:
      label = 'Full Pre-Payment'
      break
    case PaymentTerm.OTHER:
      label = 'Others'
      break
    default:
    // code block
  }
  return label
}

export const getPaymentMethodlabel = (paymentMethod: PaymentMethod | undefined) => {
  if (!paymentMethod) {
    return ''
  }
  let label
  switch (paymentMethod) {
    case PaymentMethod.PAYNOW:
      label = 'Paynow'
      break
    case PaymentMethod.CASH:
      label = 'Cash'
      break
    case PaymentMethod.CARD:
      label = 'Card Payment'
      break
    default:
    // code block
  }
  return label
}

export function replaceURLs(message: string) {
  if (!message) return '<></>'

  var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g
  return message.replace(urlRegex, function (url) {
    var hyperlink = url
    if (!hyperlink.match('^https?://')) {
      hyperlink = 'http://' + hyperlink
    }
    const result = '<a href="' + hyperlink + '" target="_blank" rel="noopener noreferrer">' + url + '</a>'
    return result
  })
}

export const getBase64FromUrl = async (url?: string) => {
  if (url) {
    const data = await fetch(url)
    const blob = await data.blob()
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.readAsDataURL(blob)
      reader.onloadend = () => {
        const base64data = reader.result
        resolve(base64data)
      }
    })
  } else {
    return ''
  }
}

export const estimateLines = (text: string | undefined, characterPerLine: number) => {
  if (text) {
    return Math.floor(text.length / characterPerLine)
  } else {
    return 1
  }
}

export const getStartOfDay = (date: string) => {
  const start = new Date(date)
  start.setUTCHours(0, 0, 0, 0)
  return start.toISOString()
}

export const getEndOfDay = (date: string) => {
  const end = new Date(date)
  end.setUTCHours(23, 59, 59, 999)
  return end.toISOString()
}

export const selectFilterOption = (input: string, option?: { label?: string; value?: string }) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

export const renderBillingAddress = (bookedServices?: RecurringBooking2ServiceDto) => {
  const country = bookedServices?.billingAddressCountry?.toUpperCase() || bookedServices?.country?.toUpperCase()
  return (
    <div>
      <span>{bookedServices?.billingAddressLine1 || bookedServices?.address1}</span>
      <br />
      <span>{bookedServices?.billingAddressLine2 || bookedServices?.address2}</span>
      <br />
      <span>
        <span>{country ? `${country}, ` : ''}</span>
        <span>{bookedServices?.billingAddressPostalCode || bookedServices?.postalCode}</span>
      </span>
      <br />
    </div>
  )
}

export const findServiceCategory = (categoryId?: string, listCategory?: ServiceCategoryDto[]) => {
  return listCategory?.find((category) => category.id === categoryId)
}

export const findParentCategory = (category?: ServiceCategoryDto, listCategory?: ServiceCategoryDto[]) => {
  return listCategory?.find((cat) => cat.id === category?.parentId)
}

export const filterUnselectService = (selecteds?: MultiSericeDto[], service?: ServiceDto, index?: number) => {
  const selectedIds = (selecteds || []).map((service) => service.serviceId)
  return selectedIds.includes(service?.id) && selectedIds.indexOf(service?.id) !== index ? false : true
}

export const getExistedPrefferPaymentTerm = (paymentTerm?: PaymentTerm) => {
  if (paymentTerm && CONFIG_PREFFER_PAYMENTTERM[paymentTerm].show) {
    return paymentTerm
  } else return undefined
}

export const sortTimeSlots = (timeSlotSessions: TimeSlotSession[]) => {
  const slotWithMidday = timeSlotSessions.map((slot) => {
    return {
      ...slot,
      midday: slot.begin.slice(-2).toUpperCase()
    }
  })
  const groupByMidday = Object.groupBy(slotWithMidday, ({ midday }) => midday)
  const result = Object.entries(groupByMidday)
    .sort((a, b) => {
      return a[0] > b[0] ? 1 : -1
    })
    .map(([midDay, slots]) => {
      let returnSlots = slots?.sort((a, b) => (a.begin.slice(0, 2) > b.begin.slice(0, 2) ? 1 : -1))
      const sessionSlotByMinute = sortTimeSlotByMinute(returnSlots as TimeSlotSession[], midDay.toUpperCase() === 'PM')
      return sessionSlotByMinute
    })
  return result.flat()
}

export const getTaskCodeNumber = (code: string) => Number(code.replace('JO', ''))

export const checkDeactiveAccount = (listTechnician?: AccountInfoDto[], assigneeId?: string) => {
  return listTechnician?.find((technician) => !technician.isActive && technician.id === assigneeId)
}

export const isTaskEditable = (status?: TaskStatus) => {
  return status === TaskStatus.CREATED || status === TaskStatus.ASSIGNED || status === TaskStatus.REASSIGN
}

export const isMainCategoryLabelAll = (category: ServiceCategoryDto | SelectedServiceCategoryDto) => {
  return category.name.toLowerCase() === 'all'
}

export const sortTimeSlotByMinute = (sessions: TimeSlotSession[], isPM: boolean) => {
  const slotWithStartHour = sessions.map((slot) => {
    return {
      ...slot,
      startHour: slot.begin.split(':')[0],
      startMinute: slot.begin.split(':')[1].substring(0, 2)
    }
  })
  const groupByStartHour = Object.groupBy(slotWithStartHour, ({ startHour }) => startHour)
  let result = Object.entries(groupByStartHour)
    .sort((a, b) => {
      return +a[0] > +b[0] ? 1 : -1
    })
    .map(([_, slot]) => {
      return slot?.sort((a, b) => {
        return a.startMinute > b.startMinute ? 1 : -1
      })
    })
    .flat()
  if (isPM) {
    const slotWith12Pm = result?.filter((slot) => slot?.begin.startsWith('12')) || []
    const slotWithout12Pm = result?.filter((slot) => !slot?.begin.startsWith('12')) || []
    result = concat(slotWith12Pm, slotWithout12Pm)
  }
  return result
}
