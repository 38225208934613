import axios from 'axios'
import { ListInvoiceResponseDto, PromoCodeFilterQuery, PromoDto, UpdatePromoBodyDto } from '../definition'
import { ENV } from '../initialization'
const DOMAIN = ENV.BACKEND_PREFIX_URL

export const asyncGetListPromoCodes = async (
  data: PromoCodeFilterQuery
): Promise<ListInvoiceResponseDto | undefined> => {
  const url = `${DOMAIN}/api/booking/promo-code`
  const response = await axios.get<ListInvoiceResponseDto>(url, {
    params: data
  })
  return response.data
}

export const asyncGetPromoCode = async (id: string): Promise<PromoDto> => {
  const response = await axios.get<PromoDto>(`${DOMAIN}/api/booking/promo-code/${id}`)
  return response.data
}

export const asyncSavePromoteCode = async (id: string, data: UpdatePromoBodyDto): Promise<void> => {
  const url = `${DOMAIN}/api/booking/promo-code/${id}`
  await axios.put(url, data)
}

export const asyncCreatePromoteCode = async (data: UpdatePromoBodyDto): Promise<void> => {
  const url = `${DOMAIN}/api/booking/promo-code`
  await axios.post(url, data)
}

export const asyncDeletePromoteCode = async (id: string): Promise<void> => {
  const url = `${DOMAIN}/api/booking/promo-code/${id}`
  await axios.delete(url)
}
