import axios from 'axios'
import { JWT_TOKEN } from './constants'

const setupAxiosInterceptors = () => {
  axios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      // Check if the error response status is 401,498 (Unauthorized)
      if (error?.response?.status === 401 || error?.response?.status === 498) {
        window.localStorage.removeItem(JWT_TOKEN)
        window.location.href = '/login'
      } else {
        return Promise.reject(error)
      }
    }
  )
}

export default setupAxiosInterceptors
