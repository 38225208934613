import { Component, ReactNode } from 'react'
import { APP_EVENTS } from '../../constants'
import { appEventEmitter } from '../../initialization'
import { preventMouseEvent } from '../../utils'
import { ConfirmModalData } from './confirm-modal.component'
import { OverlayComponent } from './overlay.component'
import { CloseCircleFilled } from '@ant-design/icons'

export interface ModalNodeProps<T = any, R = any> {
  input?: T
  onClose?: () => void
  onDone?: (result?: R) => void
  setConfirmClose?: (msg: ReactNode) => void
  onSaveWithoutClose?: (result?: R) => void
}

export interface ModalNode<T = any, R = any> {
  node: React.ComponentType<ModalNodeProps<T, R>>
  status: boolean
  title?: React.ReactNode
  input?: T
  closeConfirmMessage?: ReactNode
  onClose?: () => void
  onDone?: (result?: R) => void
  onSaveWithoutClose?: (result?: R) => void
}

interface ModalComponentState<T = any, R = any> {
  modalNodes: ModalNode<T, R>[]
}

export class ModalComponent<T = any, R = any> extends Component<any, ModalComponentState<T, R>> {
  offListener: (() => void) | undefined

  constructor(props: any) {
    super(props)
    this.state = {
      modalNodes: []
    }
  }

  componentDidMount() {
    this.offListener = appEventEmitter.on(APP_EVENTS.app_modal, (data: ModalNode<T, R>) => {
      const modalNodes = this.state?.modalNodes || []
      modalNodes.push(data)
      this.setState({ modalNodes })
    })
  }

  componentWillUnmount() {
    this.offListener && this.offListener()
  }

  onCloseModal(index: number) {
    const modalNodes = this.state?.modalNodes || []
    modalNodes.splice(index, 1)
    this.setState({ modalNodes })
  }

  onDone(index: number, data?: R) {
    const modalNodes = this.state?.modalNodes || []
    const onDone = modalNodes[index].onDone
    onDone && onDone(data)
    modalNodes.splice(index, 1)
    this.setState({ modalNodes })
  }

  onSaveWithoutClose(index: number, data?: R) {
    const modalNodes = this.state?.modalNodes || []
    const onSaveWithoutClose = modalNodes[index].onSaveWithoutClose
    onSaveWithoutClose && onSaveWithoutClose(data)
    this.setState({ modalNodes })
  }

  setConfirmClose(index: number, message: ReactNode) {
    const modalNodes = this.state?.modalNodes || []
    modalNodes[index].closeConfirmMessage = message
    this.setState({ modalNodes })
  }

  render() {
    const list = this.state?.modalNodes || []
    return (
      <>
        {list.map((data: ModalNode, index: number) =>
          renderModal(
            data,
            index,
            this.onCloseModal.bind(this),
            this.onDone.bind(this),
            this.onSaveWithoutClose.bind(this),
            this.setConfirmClose.bind(this, index)
          )
        )}
      </>
    )
  }
}

const renderModal = <R = any,>(
  data: ModalNode,
  index: number,
  onClose: (index: number) => void,
  onDone?: (index: number, data?: R) => void,
  onSaveWithoutClose?: (index: number, data?: R) => void,
  setConfirmClose?: (msg: ReactNode) => void
) => {
  const doClose = () => {
    const processClose = () => {
      data.onClose && data.onClose()
      onClose(index)
    }
    if (data.closeConfirmMessage) {
      appEventEmitter.emit<ConfirmModalData>(APP_EVENTS.app_confirm_modal, {
        title: data.closeConfirmMessage,
        onOK: processClose
      })
      return
    }
    processClose()
  }

  const doComplete = (data: R) => {
    onDone && onDone(index, data)
  }

  const doSaveWithoutClose = (data: R) => {
    onSaveWithoutClose && onSaveWithoutClose(index, data)
  }
  const Node = data.node
  return (
    <OverlayComponent key={index} display={data.status}>
      <div className='app-modal'>
        <div className='app-modal-main'>
          <div className='app-modal-header'>
            <span className='app-modal-header-title'>{data.title}</span>
            <div className='app-modal-header-close' onClick={preventMouseEvent(doClose)}>
              <CloseCircleFilled />
            </div>
          </div>
          <div className='app-modal-content'>
            <Node
              onClose={doClose}
              onDone={doComplete}
              onSaveWithoutClose={doSaveWithoutClose}
              input={data.input}
              setConfirmClose={setConfirmClose}
            />
          </div>
        </div>
      </div>
    </OverlayComponent>
  )
}
