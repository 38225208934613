import axios from 'axios'
import { QuoteFilterQuery, ListQuoteResponseDto, QuoteDto, PaymentTerm } from '../definition'
import { NewQuoteDto, UpdateQuoteDto } from '../definition/quote.dto'
import { ENV } from '../initialization'

const DOMAIN = ENV.BACKEND_PREFIX_URL

export const asyncGetListQuotes = async (data: QuoteFilterQuery): Promise<ListQuoteResponseDto | undefined> => {
  const url = `${DOMAIN}/api/booking/booking/quote/list`
  const response = await axios.get<ListQuoteResponseDto>(url, {
    params: data
  })
  return response.data
}

export const asyncGetQuoteDetail = async (quoteId: string): Promise<QuoteDto> => {
  const url = `${DOMAIN}/api/booking/booking/quote/${quoteId}`
  const response = await axios.get<QuoteDto>(url)
  return response.data
}

export const asyncCreateQuote = async (data: NewQuoteDto): Promise<QuoteDto> => {
  const url = `${DOMAIN}/api/booking/booking/quote/create`
  const createdQuote = await axios.post(url, data)
  return createdQuote.data
}

export const asyncUpdateQuote = async (id: string, updateQuote: UpdateQuoteDto): Promise<void> => {
  const url = `${DOMAIN}/api/booking/booking/quote/update/${id}`
  await axios.put(url, updateQuote)
}

export const asyncGetGst = async (): Promise<number> => {
  const url = `${DOMAIN}/api/booking/get-static-data`
  const response = await axios.get<any>(url)
  return response.data.gst || 7
}

export const asyncAttachQuoteBooking = async (
  quoteId: string,
  bookingId: string,
  paymentTerm?: PaymentTerm,
  paymentTermValue?: number
): Promise<void> => {
  const url = `${DOMAIN}/api/booking/booking/quote/attach`
  await axios.post(url, {
    quoteId: quoteId,
    bookingId: bookingId,
    paymentTerm: paymentTerm,
    paymentTermValue: paymentTermValue
  })
}

export const asyncSendQuote = async (id: string, image: any): Promise<void> => {
  const url = `${DOMAIN}/api/booking/booking/quote/send-details/${id}`
  let bodyFormData = new FormData()

  bodyFormData.append('attachFile', image)
  await axios({
    method: 'post',
    url: url,
    data: bodyFormData,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}
