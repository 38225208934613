import { Component } from 'react'
import { APP_EVENTS } from '../../constants'
import { LoadingEmitData } from '../../definition'
import { appEventEmitter } from '../../initialization'
import { OverlayComponent } from './overlay.component'

import loadingIcon from './../../styles/images/blocks-wave.svg'

export class LoadingComponent extends Component<
  any,
  {
    displayOverlay: boolean
    messageDisplay: string
  }
> {
  offListener: (() => void) | undefined

  componentDidMount() {
    this.offListener = appEventEmitter.on(APP_EVENTS.app_loading, (data: LoadingEmitData) => {
      this.setState({
        displayOverlay: data.display,
        messageDisplay: data.message
      })
    })
  }

  componentWillUnmount() {
    this.offListener && this.offListener()
  }

  render() {
    return (
      <OverlayComponent display={this.state?.displayOverlay}>
        <div className='loading'>
          {/* <div className="loading-message"> */}
          <img alt='loading' src={loadingIcon} width={150} height={150} />
          {/* {this.state?.messageDisplay ||
              this.props.message ||
              'Processing....'} */}
          {/* </div> */}
        </div>
      </OverlayComponent>
    )
  }
}
