import { CustomerRole, ResidentialType } from './_.dto'
import { PaymentTerm } from './invoice.dto'

export enum COUNTRY_CODE {
  _65 = '+65',
  _86 = '+86',
  _60 = '+60',
  _62 = '+62',
  _84 = '+84'
}

export interface NewCustomerRequestBodyDto {
  email?: string
  password: string
  name: string
  contact: string
  countryCode?: string
  avatarId?: string
  avatarIsTemporary?: boolean
  customerRole: CustomerRole
  organizationName?: string
  remark?: string
  paymentTerm?: PaymentTerm
  paymentTermValue?: number
  designatedAddressLine1?: string
  designatedAddressLine2?: string
  designatedAddressPostalCode?: string
  designatedAddressCity?: string
  designatedAddressCountry?: string
  designatedResidentialType?: ResidentialType

  billingAddressLine1?: string
  billingAddressLine2?: string
  billingAddressPostalCode?: string
  billingAddressCity?: string
  billingAddressCountry?: string
  billingResidentialType?: ResidentialType

  isSameDesignatedAddress?: boolean
}

export interface UpdateCustomerRequestBodyDto {
  name: string
  email?: string
  contact: string
  countryCode?: string
  avatarId?: string
  avatarIsTemporary?: boolean

  customerRole: CustomerRole
  organizationName?: string
  paymentTerm?: PaymentTerm
  paymentTermValue?: number

  remark?: string

  designatedAddressLine1?: string
  designatedAddressLine2?: string
  designatedAddressPostalCode?: string
  designatedAddressCity?: string
  designatedAddressCountry?: string
  designatedResidentialType?: ResidentialType

  billingAddressLine1?: string
  billingAddressLine2?: string
  billingAddressPostalCode?: string
  billingAddressCity?: string
  billingAddressCountry?: string
  billingResidentialType?: ResidentialType

  isSameDesignatedAddress?: boolean
}

export enum AddressType {
  Designated = 'DESIGNATED',
  Billing = 'BILLING'
}

export const AddressTypeLabel = {
  [AddressType.Designated]: 'Designated',
  [AddressType.Billing]: 'Billing'
}

export interface CustomerAddressDto {
  accountId: string
  addressName?: string
  addressLine1: string
  addressLine2: string
  addressPostalCode: string
  addressCity: string
  addressCountry: string
  residentialType?: ResidentialType
  isPrimary?: boolean
  id?: string
  addressType?: AddressType
}

export interface TempCustomerAddressDto {
  addressName: string
  addressLine1: string
  addressLine2: string
  addressPostalCode: string
  addressCity: string
  addressCountry: string
  residentialType: ResidentialType
  isPrimary: boolean
  addressType: AddressType
}

export interface ListAddressoResponseDto {
  items: CustomerAddressDto[]
  count: number
}

export interface UpdateCustomerAddressDto {
  accountId: string
  addressType: AddressType
  addressName: string
  addressLine1: string
  addressLine2: string
  addressPostalCode: string
  addressCity: string
  addressCountry: string
  residentialType?: ResidentialType
  isPrimary: boolean
}
