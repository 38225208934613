import { AccountInfoDto } from './account.dto'
import { BookingDto, BOOKING_TYPE, RecurringFrequency } from './booking.dto'
import { InvoiceDto } from './invoice.dto'
import { DISCOUNT_TYPE } from './promo.dto'
import { ServiceDto } from './service.dto'
export interface QuoteFilterQuery {
  offset?: number
  limit?: number
  search?: string
  accountId?: string
  status?: QUOTE_STATUS
  bookingType?: BOOKING_TYPE
  serviceIds?: string
  fromDate?: string
  toDate?: string
  export?: boolean
  sortType?: string
  sortBy?: string
  amount?: string
}

export enum QUOTE_STATUS {
  CREATED = 'CREATED',
  ATTACHED = 'ATTACHED',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED'
}

export enum SortQuoteField {
  AMOUNT = 'amount',
  STATUS = 'status',
  CREATE_AT = 'createdAt',
  QUOTE_NO = 'quoteNo',
  CUSTOMER_NAME = 'customerName',
  SERVICE_NAME = 'serviceName',
  INVOICE = 'invoice'
}

export interface Quote2ServiceDto {
  id?: string
  quantity: number
  amount: number
  discount: number
  serviceId?: string // In case create new, it must be an id
  service?: ServiceDto
  durationInYears?: number
  timesPerYear?: number
  notes?: string
  discountType?: DISCOUNT_TYPE
  frequency?: RecurringFrequency
  name?: string
}

export interface QuoteDto {
  id: string
  code: string
  accountId: string
  account?: AccountInfoDto
  gst: number
  status: QUOTE_STATUS
  date: Date
  bookingType: BOOKING_TYPE
  booking?: BookingDto
  quoteToServices?: Quote2ServiceDto[]
  invoice?: InvoiceDto
  createdAt: Date
  adminAccept?: string
  serviceCharge: number
  surcharge?: number
}
export interface NewQuoteDto {
  customerId: string
  date: Date
  bookingType: BOOKING_TYPE // Booking service bookingType
  serviceSummaries?: Quote2ServiceDto[]
  surcharge?: number
}

export interface UpdateQuoteDto {
  customerId: string
  date: Date
  bookingType: BOOKING_TYPE // Booking service bookingType
  serviceSummaries?: Quote2ServiceDto[]
  surcharge?: number
}

export interface ListQuoteResponseDto {
  items: QuoteDto[]
  count: number
}
