import axios from 'axios'
import {
  AdminConfirmPaymentDto,
  AdminCreateVOInvoiceRequestBodyDto,
  InvoiceDto,
  InvoiceFilterQuery,
  ListInvoiceResponseDto,
  AdminUpdatePaymentTermDto,
  AdminUpdatePrePaymentMethodDto
} from '../definition'

import { ENV } from '../initialization'
const DOMAIN = ENV.BACKEND_PREFIX_URL

export const asyncGetListInvoices = async (data: InvoiceFilterQuery): Promise<ListInvoiceResponseDto | undefined> => {
  const url = `${DOMAIN}/api/booking/invoice/list`
  const response = await axios.get<ListInvoiceResponseDto>(url, {
    params: data
  })
  return response.data
}

export const asyncGetInvoiceDetail = async (id: string): Promise<InvoiceDto | undefined> => {
  const url = `${DOMAIN}/api/booking/invoice/detail/${id}`
  const response = await axios.get<InvoiceDto>(url)
  return response.data
}

export const asyncCreateVOInvoices = async (data: AdminCreateVOInvoiceRequestBodyDto) => {
  const url = `${DOMAIN}/api/booking/invoice/vo-invoice/create`
  await axios.post(url, data)
}

export const asyncConfirmPaymentInvoice = async (id: string, data: AdminConfirmPaymentDto) => {
  const url = `${DOMAIN}/api/booking/invoice/confirm-payment/${id}`
  await axios.put(url, data)
}

export const asyncConfirmPaymentVoInvoice = async (id: string, data: AdminConfirmPaymentDto) => {
  const url = `${DOMAIN}/api/booking/invoice/vo-invoice/confirm-payment/${id}`
  await axios.put(url, data)
}

export const asyncRemoveVoInvoice = async (id: string) => {
  const url = `${DOMAIN}/api/booking/invoice/vo-invoice/remove/${id}`
  await axios.delete(url)
}

export const asyncUpdatePaymentTermInvoice = async (id: string, data: AdminUpdatePaymentTermDto) => {
  const url = `${DOMAIN}/api/booking/invoice/update-payment-term/${id}`
  await axios.put(url, data)
}

export const asyncUpdatePrePaymentMethodInvoice = async (id: string, data: AdminUpdatePrePaymentMethodDto) => {
  const url = `${DOMAIN}/api/booking/invoice/confirm-pre-payment/${id}`
  await axios.put(url, data)
}

export const asyncSendInvoice = async (id: string, image: any): Promise<void> => {
  const url = `${DOMAIN}/api/booking/invoice/send-details/${id}`
  let bodyFormData = new FormData()

  bodyFormData.append('attachFile', image)
  await axios({
    method: 'post',
    url: url,
    data: bodyFormData,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

export const asyncSendSubInvoice = async (id: string, image: any): Promise<void> => {
  const url = `${DOMAIN}/api/booking/invoice/vo-invoice/send-details/${id}`
  let bodyFormData = new FormData()

  bodyFormData.append('attachFile', image)
  await axios({
    method: 'post',
    url: url,
    data: bodyFormData,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}
