import { ResidentialType } from './_.dto'
import { AccountInfoDto } from './account.dto'
import { InvoiceDto, PaymentMethod, PaymentTerm } from './invoice.dto'
import { MiscNoteDto } from './notes.dto'
import { PromoCodeUsed } from './promo.dto'
import { QuoteDto } from './quote.dto'
import { ServiceDto } from './service.dto'
import { TechnicianProfileDto } from './technician.dto'

export enum BOOKING_TYPE {
  ONE_TIME = 'One Time',
  RECURRING = 'Recurring'
}

export enum SuggestField {
  NAME = 'CustomerName',
  PHONE = 'ContactNumber'
}

export enum BookingStatus {
  CANCELED = 0,

  NEW = 1, // Value range [1 -> 10]

  QUOTE_ATTACHED = 11, // Value range [11 -> 20]
  NEW_QUOTE_REQUESTED = 21, // Value range [21 -> 30]
  QUOTE_REJECTED = 31, // Value range [31 ->  40]
  QUOTE_ACCEPTED = 32, // Value range [31 ->  40]

  SELF_PLANNED = 41, // Value range [41 -> 50]
  ADMIN_PLANNING = 42, // Value range [41 -> 50]

  ADMIN_PLANNED = 51, // Value range [51 -> 60]

  INVOICE_PAID = 61, // Value range [61 -> 70]

  DONE = 71 // Value range [71 -> 80]
}
export enum TaskStatus {
  CREATED = 'CREATED',
  ASSIGNED = 'ASSIGNED',
  ON_THE_WAY = 'ON_THE_WAY',
  STARTED = 'STARTED',
  COMPLETED = 'COMPLETED', // complete service
  DONE = 'DONE', // complete task
  CANCELED = 'CANCELED',
  REASSIGN = 'REASSIGN'
}

export enum RecurringFrequency {
  Weekly = 'Weekly',
  BiWeekly = 'BiWeekly'
}

export interface TaskDto {
  id: string
  code: string
  accountId: string
  account?: AccountInfoDto
  accountSign?: string
  accountSignUrl?: string
  bookingId: string
  booking?: BookingDto
  timeSlotBeginAt?: Date
  timeSlotEndAt?: Date
  date: Date
  residentialType?: ResidentialType
  status: TaskStatus
  assigneeId?: string
  assignee?: AccountInfoDto
  assigneeRemark?: string
  assigneeSign?: string
  assigneeSignUrl?: string
  images?: string[]
  address1: string
  address2: string
  postalCode: string
  city: string
  country: string
  service?: ServiceDto
  services?: ServiceDto[]
  serviceId: string
  serviceIds: string[]
  notes?: any
  preferredMonth?: number
  preferredDayInWeek?: string
  preferredTimeInDay?: string
  timeCompleted?: Date
  rejectedBy?: RejectTechnicianDto
}

export interface UpdateTaskRequestBodyDto {
  taskId: string
  timeSlotBeginAt: Date
  timeSlotEndAt: Date
  assigneeId: string
  address1: string
  address2: string
  postalCode: string
  city: string
  country: string
  residentialType?: ResidentialType
}

export interface ServiceSummaryDto {
  id?: string
  serviceId: string
  service?: ServiceDto
  quantity?: number
  amount?: number
  discount?: number
  durationInYears?: number
  timesPerYear?: number
  notes?: string
}

export interface BookingDto {
  id: string
  accountId: string
  account?: AccountInfoDto
  type: BOOKING_TYPE
  status: BookingStatus
  code: string
  comingTask?: TaskDto
  bookedServices?: RecurringBooking2ServiceDto[]
  quote?: QuoteDto
  tasks?: TaskDto[]
  createdAt: Date
  invoice?: InvoiceDto
  miscNote?: MiscNoteDto
  paymentTerm?: PaymentTerm
  paymentTermValue?: number
  paymentMethodPre?: PaymentMethod
  promoCodeUsed?: PromoCodeUsed
  notes?: string
}

export interface ServiceCategories {
  main: {
    id: string
    name: string
  }
  sub: {
    id: string
    name: string
  }
  child: {
    id: string
    name: string
  }
}

export interface NewBookingDto {
  jobOrderNo?: string // BE has to generate next num
  type: BOOKING_TYPE
  timeSlotBeginAt: Date // Extract appointment from this time
  timeSlotEndAt: Date
  customerId: string
  assigneeId?: string
  serviceId?: string
  // One time booking should have only one quote?
  quoteId?: string
  address1?: string
  address2?: string
  postalCode?: string
  city?: string
  country?: string
  miscNoteId?: string
  paymentTerm?: PaymentTerm
  paymentTermValue?: number
  residentialType?: ResidentialType
  notes?: string

  billingAddressLine1?: string
  billingAddressLine2?: string
  billingAddressPostalCode?: string
  billingAddressCity?: string
  billingAddressCountry?: string
  billingResidentialType?: string
}

export interface RecurringBooking2ServiceDto {
  serviceId?: string
  service?: ServiceDto // Null if creating new
  address1: string
  address2?: string
  postalCode: string
  city: string
  country: string
  durationInYears?: number
  timesPerYear?: number
  notes?: string
  mainCategoryId?: string
  subCategoryId?: string
  childCategoryId?: string
  serviceCategories?: ServiceCategories
  residentialType?: ResidentialType | ''
  billingAddressLine1?: string
  billingAddressLine2?: string
  billingAddressPostalCode?: string
  billingAddressCity?: string
  billingAddressCountry?: string
  billingResidentialType?: ResidentialType
  isSameDesignatedAddress?: boolean
  frequency?: RecurringFrequency
}

export interface NewRecurringBookingDto {
  // If not exist, the customer must be created first
  customerId: string
  quoteId: string
  serviceSummaries: RecurringBooking2ServiceDto[]
  miscNoteId?: string
  paymentTerm?: PaymentTerm
  paymentTermValue?: number
}

export interface UpdateRecurringBookingDto {
  bookingId: string
  quoteId?: string
  serviceSummaries?: RecurringBooking2ServiceDto[]
  miscNoteId?: string
  paymentTerm?: PaymentTerm
  paymentTermValue?: number
}

export interface ContractBookingListFilterQuery {
  offset?: number

  limit?: number

  // sortColumn: string;

  // sortType: OrderType;

  search?: string

  status?: BookingStatus

  isNewBooking?: boolean
}

export interface AdminGetCustomerBookingsFilterQuery {
  offset?: number
  limit?: number
  accountId: string
  bookingType?: BOOKING_TYPE
  export?: boolean
}

export interface ListBookingResponseDto {
  items: BookingDto[]

  count: number
}

export interface TaskFilterQuery {
  offset?: number
  limit?: number
  startTime: Date
  endTime: Date
  newBooking?: boolean
  recurringBookingRequest?: boolean
  assigned?: boolean
  reAssigned?: boolean
  statuses?: string[]
  notes?: boolean
  contractBooking?: boolean
  oneTimeBooking?: boolean
  assignee?: string
  search?: string
  serviceId?: string
}

export interface ListTaskResponseDto {
  items: TaskDto[]
  count: number
}

export interface StaticDataResponseDto {
  gst: number
  admin_contact_phone_number: string
  paynow_qrcode_value: string
  quote_term?: string
  invoice_term?: string
  service_charge?: number
}

export interface StaticDataRequestBodyDto {
  gst?: number
  quote_term?: string
  invoice_term?: string
  service_charge?: number
}

export interface UpdateTaskAppointmentDto {
  bookingId: string
  appointments: TaskAppointmentDto[]
  miscNoteId?: string
  paymentTerm?: string
  paymentTermValue?: number
}

export interface TaskAppointmentDto {
  taskId: string
  timeSlotBeginAt?: Date
  timeSlotEndAt?: Date
  assigneeId?: string
}

export interface ConfigCutoffDurationDto {
  hours: string
  mins: string
}

export interface ConfigPaymentTimeframeDto {
  hours: string
  mins: string
}

export interface RejectTechnicianDto {
  accountType: string
  employeeRoleId: string
  id: string
  profile: TechnicianProfileDto
}

export enum TaskType {
  Upcoming = 'upcoming',
  Past = 'past'
}

export interface AdminGetTaskByAccountIdFilterQuery {
  accountId: string
  type?: TaskType
  bookingType?: BOOKING_TYPE
  offset?: number
  limit?: number
}
