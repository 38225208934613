import { DISCOUNT_TYPE, PromoCodeUsed, PromoteCategory, Quote2ServiceDto } from '../definition'

export const getEachServiceSimpleAmount = (service: Quote2ServiceDto) => {
  let total
  const timesPerYear = service.timesPerYear || 1
  const durationInYears = service.durationInYears || 1

  total = timesPerYear * durationInYears * service.quantity * service.amount
  return total
}

export const getSubtotalAmount = (services?: Quote2ServiceDto[]) => {
  const subTotal =
    (services || [])
      .map((service) => {
        return getEachServiceSimpleAmount(service)
      })
      .reduce((res, cur) => {
        return (res += cur)
      }, 0) || 0
  return subTotal
}

export const checkIfServiceHavePromo = (promoCodeUsed: PromoCodeUsed | undefined, serviceId?: string) => {
  if (promoCodeUsed?.promoCode.selectedCategoriesOnly === false) return true
  const promoCategory: PromoteCategory[] = promoCodeUsed?.promoCode.categories || []
  return promoCategory.some((service) => service.serviceId === serviceId)
}

export const getEachServiceDiscount = (service: Quote2ServiceDto, promoCodeUsed?: PromoCodeUsed) => {
  if (checkIfServiceHavePromo(promoCodeUsed, service.serviceId)) {
    if (promoCodeUsed?.promoCode.discountType === DISCOUNT_TYPE.PERCENT) {
      return (getEachServiceSimpleAmount(service) * (promoCodeUsed?.promoCode.discountValue || 0)) / 100
    } else if (promoCodeUsed?.promoCode.discountType === DISCOUNT_TYPE.FIXED) {
      return promoCodeUsed?.promoCode.discountValue || 0
    } else {
      return 0
    }
  } else {
    if (service.discountType === DISCOUNT_TYPE.PERCENT) {
      return (getEachServiceSimpleAmount(service) * service.discount) / 100
    } else {
      const quantity = service.quantity || 1
      const timesPerYear = service.timesPerYear || 1
      const durationInYears = service.durationInYears || 1
      return service.discount * timesPerYear * durationInYears * quantity
    }
  }
}

export const getTotalDiscount = (services?: Quote2ServiceDto[], promoCodeUsed?: PromoCodeUsed) => {
  const subTotal =
    (services || [])
      .map((service) => {
        return getEachServiceDiscount(service, promoCodeUsed)
      })
      .reduce((res, cur) => {
        return (res += cur)
      }, 0) || 0
  return subTotal
}

export const getTotalTax = (
  services?: Quote2ServiceDto[],
  gst?: number,
  promoCodeUsed?: PromoCodeUsed,
  surcharge?: number,
  serviceCharge?: number
) => {
  const subTotal =
    (services || [])
      .map((service) => {
        let eachServiceDiscount = getEachServiceDiscount(service, promoCodeUsed)
        return getEachServiceSimpleAmount(service) - eachServiceDiscount
      })
      .reduce((res, cur) => {
        return (res += cur)
      }, 0) || 0
  return ((subTotal + (surcharge || 0) + (serviceCharge || 0)) * (gst || 0)) / 100
}

export const renderDiscountLabel = (service: Quote2ServiceDto, promoCodeUsed?: PromoCodeUsed) => {
  if (!promoCodeUsed) {
    return service.discountType === DISCOUNT_TYPE.PERCENT ? '%' : '$'
  }
  if (promoCodeUsed && checkIfServiceHavePromo(promoCodeUsed, service.serviceId)) {
    if (promoCodeUsed.promoCode.discountType === DISCOUNT_TYPE.FIXED) {
      return '$'
    } else {
      return '%'
    }
  }
  return '%'
}

export const renderDiscountValue = (service: Quote2ServiceDto, promoCodeUsed?: PromoCodeUsed) => {
  if (!promoCodeUsed) {
    return service.discount
  }
  if (promoCodeUsed && checkIfServiceHavePromo(promoCodeUsed, service.serviceId)) {
    return promoCodeUsed.promoCode.discountValue
  } else {
    return service.discount.toFixed(2)
  }
}

export const renderDiscountPromo = (promoCodeUsed?: PromoCodeUsed) => {
  return promoCodeUsed ? ` (Promo code: ${promoCodeUsed.code})` : ''
}

interface QuoteCaculationDto {
  services?: Quote2ServiceDto[]
  promoCodeUsed?: PromoCodeUsed
  gst?: number
  surcharge?: number
  serviceCharge?: number
}

export const quoteCalculation = (params: QuoteCaculationDto) => {
  const subTotal = getSubtotalAmount(params.services)

  const totalDiscount = getTotalDiscount(params.services, params.promoCodeUsed)

  const amountAfterDiscount = subTotal - totalDiscount

  const totalTax = getTotalTax(
    params.services,
    params.gst,
    params.promoCodeUsed,
    params.surcharge,
    params.serviceCharge
  )

  const finalTotal = amountAfterDiscount + totalTax + (params.surcharge || 0) + (params.serviceCharge || 0)

  return { subTotal, totalDiscount, amountAfterDiscount, totalTax, finalTotal }
}
