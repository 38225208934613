import { ScheduleCalendarConfigurationType } from './time-slot.dto'
import { AccountType, CustomerRole, ResidentialType } from './_.dto'
import { PaymentTerm } from './invoice.dto'
import { EmployeeRoleDto } from './technician.dto'

export enum AccountPasswordStatus {
  RESET = 'RESET'
}

export interface AuthenticationData {
  accountInfo?: CurrentAccountInfoResponseDto
  configuration?: ScheduleCalendarConfigurationType
  companyInfo?: CurrentCompanyInfoInfoResponseDto
}

export interface ProfileDto {
  name: string
  contact: string
  avatarUrl?: string
  avatarId?: string
  countryCode?: string
}

export interface ProfileExtraCustomerDto {
  customerRole: CustomerRole
  organizationName?: string

  remark?: string

  paymentTerm: PaymentTerm
  paymentTermValue: number
  designatedAddressLine1?: string
  designatedAddressLine2?: string
  designatedAddressPostalCode?: string
  designatedAddressCity?: string
  designatedAddressCountry?: string
  designatedResidentialType?: ResidentialType

  billingAddressLine1?: string
  billingAddressLine2?: string
  billingAddressPostalCode?: string
  billingAddressCity?: string
  billingAddressCountry?: string
  billingResidentialType?: ResidentialType
  isSameDesignatedAddress?: boolean
}

export interface AccountInfoDto {
  id: string
  email: string
  employeeRole?: EmployeeRoleDto
  employeeRoleId?: string
  accountType: AccountType
  socialLogin?: string
  createdAt: Date
  profile?: ProfileDto
  profileExtraCustomer?: ProfileExtraCustomerDto
  isActive: Boolean
  paymentTerm?: PaymentTerm
  paymentTermValue?: number
}

export interface CurrentAccountInfoResponseDto {
  id: string
  email: string
  passwordStatus: AccountPasswordStatus
  accountType: AccountType
  profile?: ProfileDto
}

export interface CurrentCompanyInfoInfoResponseDto {
  name?: string
  email?: string
  tel?: string
  logo?: string
  addressLine1?: string
  addressLine2?: string
  postalCode?: string
  registrationNo?: string
  urgentContact?: string
  paynowName?: string
  paynowQRCode?: string
  urgentCountryCode?: string
}

export interface ListAccountInfoResponseDto {
  items: AccountInfoDto[]

  count: number
}
