import { Component, ReactNode, StrictMode, Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { AccountPasswordStatus, AuthenticationData } from './definition'
import { AppRouter } from './views'

import './styles/fonts/Poppins/Poppins-Bold.ttf'
import './styles/fonts/Poppins/Poppins-Light.ttf'
import './styles/fonts/Poppins/Poppins-Medium.ttf'
import './styles/fonts/Poppins/Poppins-Regular.ttf'
import './styles/fonts/Poppins/Poppins-SemiBold.ttf'

import 'react-toastify/dist/ReactToastify.css'
import './styles/App.scss'
import { ChangePasswordModal } from './views/components/change-password.component'
import { ConfirmModalComponent } from './views/components/confirm-modal.component'
import { LoadingComponent } from './views/components/loading.component'
import { ModalComponent } from './views/components/modal.component'

export class Application extends Component<AuthenticationData> {
  render(): ReactNode {
    return (
      <Suspense fallback={<LoadingComponent message='Loading....' />}>
        <StrictMode>
          <BrowserRouter>
            <AppRouter {...this.props} />
          </BrowserRouter>
          <ToastContainer theme='colored' />
          {this.props.accountInfo?.passwordStatus === AccountPasswordStatus.RESET && <ChangePasswordModal />}
          <ModalComponent key='app-modal' />
          <ConfirmModalComponent />
          <LoadingComponent message='Loading....' />
        </StrictMode>
      </Suspense>
    )
  }
}
